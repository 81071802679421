import Logo2019 from './2019_logo.png';
import Logo2019Active from './2019_logo_active.png';
import bgSlide1 from './bgSlide1.jpg';
import bgSlide2 from './bgSlide2.jpg';
import bg1 from './bg.png';
import bg2 from './bg.png';
import bg3 from './bg.png';
import bgStartPage from './bgStartPage.jpg';
import bgStartPageBlue from './bgStartPageBlue.png';
// import aimforthebestLogo from './logo_aim_for_the_best.png';
import bgLionLeft from './bgLionLeft.png';
import bgLionLeft2 from './bgLionLeft2.png';
import bgLionRight from './bgLionRight.png';
import bgELearning from './bgELearning.jpg';
import bgBackground from './bg_horizontal.jpg';
import bgBackgroundMobile from './bg_vertikal.jpg';
import bgBackground2 from './bg_horizontal_2.jpg';
import bgBackground3 from './bg_horizontal_3.jpg';
import bgBackgroundMobile2 from './bg_vertikal_2.jpg';
import bgBackgroundMobile3 from './bg_vertikal_3.jpg';
import GooglePlay from './google_play.png';
import TempImage from './temp.png';
import ELearningAppDownload from './e_learning_app_download.png';
import ELearningAppDownloadQuiz from './e_learning_app_download_quiz.png';
import ELearningShapeLeft from './e_learning_shape_left.png';
import ELearningShapeRight from './e_learning_shape_right_top.png';
import PhoneImage from './phone.png';
import PhoneImage1 from './phone1.png';
import PushYourLimit from './pushYourLimits.png';
import Temp1 from './temp1.png';
import Temp2 from './temp2.png';
import Temp3 from './temp3.png';
import Temp4 from './temp3.png';
import ELearningSamSung from './samsung_phone.png';
import BtnELearningStart from './e_learning_starten.png';
import BtnFactSheet from './fact_sheets.png';
import FactSheet from './fact-sheet.svg';
import BtnQuizStart from './quiz_starten.png';
import ETraningLandscape from './e_training_landscape.jpg';
import ETraningPortrait from './e_training_portrait.jpg';
import GalaxyWatch from './e_training_samsung_watch.jpg';
import GalaxyS8 from './e_training_samsung_s8.jpg';
import GalaxyA6 from './e_training_sumsung_a6.jpg';
import DummyContent from './dummycontent.png';
import GalaxyS9 from './e_training_sumsung_s9.jpg';
import GalaxyS10 from './e_training_s10.jpg';
import GalaxyA from './e_training_samsung_galaxy_a.jpg';
import GalaxyNote9 from './e_training_sumsung_note9.jpg';
import QuizJanImage from './quizJanImage.jpg';
import IntroModalAd from './intro_modal_ad2.png';
import PlayVideoPoster from './play_video_poster.png';
import onAnalysis from './on_analysis.png';
import offAnalysis from './off_analysis.png';
import onCookies from './on_cookies.png';
import offCookies from './off_cookies.png';
import NewWiciAppLogo from './winnerscircle2022.png';
import TLogo from './T_Logo.png';
export default {
  Logo2019,
  Logo2019Active,
  bgSlide1,
  bgSlide2,
  bg1,
  bg2,
  bg3,
  bgStartPage,
  bgStartPageBlue,
  // aimforthebestLogo,
  bgLionLeft,
  bgLionLeft2,
  bgLionRight,
  bgELearning,
  bgBackground,
  bgBackgroundMobile,
  bgBackground2,
  bgBackground3,
  bgBackgroundMobile2,
  bgBackgroundMobile3,
  GooglePlay,
  TempImage,
  PhoneImage,
  PhoneImage1,
  GalaxyWatch,
  GalaxyS8,
  GalaxyA6,
  GalaxyA,
  DummyContent,
  GalaxyS9,
  GalaxyS10,
  GalaxyNote9,
  PushYourLimit,
  Temp1,
  Temp2,
  Temp3,
  Temp4,
  ELearningSamSung,
  BtnELearningStart,
  BtnFactSheet,
  FactSheet,
  BtnQuizStart,
  ELearningAppDownload,
  ELearningAppDownloadQuiz,
  ELearningShapeLeft,
  ELearningShapeRight,
  ETraningLandscape,
  ETraningPortrait,
  QuizJanImage,
  IntroModalAd,
  PlayVideoPoster,
  onAnalysis,
  offAnalysis,
  onCookies,
  offCookies,
  NewWiciAppLogo,
  TLogo,
};
