/* eslint react/style-prop-object: 0 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 798.8 357"
      style="enable-background:new 0 0 798.79 357.05;"
      xmlSpace="preserve"
      {...props}>
      <g>
        <path
          fill="#FFF"
          d="M80.3,240.2c0,25.8,11.6,41.5,31.1,41.5s25.5-9.4,28.2-24.8h-5.3c-2.5,12.7-11.2,20-22.9,20s-26.1-13.6-26.1-36.7,9.5-36.7,26.1-36.7,20.4,7.4,22.9,20h5.3c-2.8-15.4-13.5-24.8-28.2-24.8-19.5,0-31.1,15.7-31.1,41.5h0ZM157.4,280.5h4.8v-80.6h-4.8v80.6h0ZM229.9,280.5h5.8l-23.2-34.5c11.8-.6,22.5-7.5,22.5-23s-11.6-23-24-23h-25.2v80.6h4.8v-34.6h16.2l23.1,34.5h0ZM190.7,204.5h19.9c9.8,0,19.4,4.9,19.4,18.5s-9.7,18.5-19.4,18.5h-19.9v-37h0ZM249.1,240.2c0,25.8,11.6,41.5,31.1,41.5s25.5-9.4,28.2-24.8h-5.3c-2.5,12.7-11.2,20-22.9,20s-26.1-13.6-26.1-36.7,9.5-36.7,26.1-36.7,20.4,7.4,22.9,20h5.3c-2.8-15.4-13.5-24.8-28.2-24.8-19.6,0-31.1,15.7-31.1,41.5h0ZM326.1,280.5h40v-4.6h-35.1v-76h-4.8v80.6h-.1ZM423.8,204.5v-4.6h-42.3v80.6h42.3v-4.6h-37.5v-34.5h36.3v-4.6h-36.3v-32.3h37.5Z"
        />
        <path
          fill="#FFF"
          d="M168.6,79.3l-13,55.4h-.7l-13-55.4h-18.3l-12.9,55.4h-.7l-13.3-55.4h-16.8l21.3,80.6h17.6l13.4-54.6h.7l13.4,54.6h17.7l21.3-80.6h-16.7ZM195,159.8h16.3v-80.5h-16.3v80.5h0ZM273.9,79.3v50.7h-.7l-29-50.7h-16.5v80.6h16.3v-50.4h.7l28.8,50.4h16.5v-80.6h-16.1ZM352.6,79.3v50.7h-.7l-29-50.7h-16.5v80.6h16.3v-50.4h.7l28.8,50.4h16.5v-80.6h-16.1ZM432.6,94.3v-15h-47.4v80.6h47.4v-15.1h-31.1v-18.4h29.9v-15.1h-29.9v-17h31.1ZM486.4,159.8h18.1l-17-31.1c9.8-3.3,16.2-11.6,16.2-23.9s-11.1-25.6-26.8-25.6h-29.8v80.6h16.3v-29.4h7.8l15.2,29.4h0ZM463.4,94.3h12.6c6.4,0,10.7,3.7,10.7,10.5s-4.3,10.5-10.7,10.5h-12.7v-21h.1ZM540.8,161.7c15.2,0,27.1-9,27.1-25.2s-7.5-20.6-19.4-23.8l-9.3-2.5c-4.5-1.2-8.2-3.3-8.2-8.7s4.2-8.8,9.8-8.8,9.7,3,10.7,9h15.9c-1.6-14.8-10.4-24.2-26.7-24.2s-26.4,9.8-26.4,25.1,5.1,19.2,18,22.7l9.3,2.7c6.3,1.7,9.7,4.2,9.7,9.5s-3.5,9.1-10.5,9.1-10.4-2.7-11.8-9.4h-16.2c1.5,15.8,11.4,24.5,28,24.5h0ZM578.3,79.3v18.6h8.9c-.4,7.1-2,10.7-8.8,11.1v8.6c13.4-.6,18.4-8.7,18.4-23v-15.3h-18.5Z"
        />
      </g>
      <g>
        <path
          fill="#FFF"
          d="M476.9,280.4v-6.3l30.3-29.2c5.5-5.3,11.2-11.8,11.2-22.8s-7.6-19.3-18.3-19.3-17.8,6.2-19.2,16.5h-4.9c1.5-11.6,9.1-21.1,24-21.1s23.2,9.6,23.2,23.9-5.1,18.9-13,26.4l-28,26.7v.6h41.4v4.6h-46.7Z"
        />
        <path
          fill="#FFF"
          d="M538.2,251.6v-23.3c0-19.8,11.6-30.1,26.4-30.1s26.4,10.3,26.4,30.1v23.3c0,19.8-11.6,30.1-26.4,30.1s-26.4-10.3-26.4-30.1h0ZM586.2,251.4v-23c0-16.9-9-25.6-21.6-25.6s-21.6,8.7-21.6,25.6v23c0,16.9,9,25.6,21.6,25.6s21.6-8.7,21.6-25.6Z"
        />
        <path
          fill="#FFF"
          d="M605.6,280.4v-6.3l30.3-29.2c5.5-5.3,11.2-11.8,11.2-22.8s-7.6-19.3-18.3-19.3-17.8,6.2-19.2,16.5h-4.9c1.5-11.6,9.1-21.1,24-21.1s23.2,9.6,23.2,23.9-5.1,18.9-13,26.4l-28,26.7v.6h41.4v4.6h-46.7Z"
        />
        <path
          fill="#FFF"
          d="M663.7,257.9h4.7c1,11.8,8.5,19.2,19.9,19.2s20.5-8.5,20.5-21.1-8.6-21-22.7-21h-18.6l3.8-35.5h41.6v4.6h-37.4l-2.8,26.3h13.7c16.4,0,27.2,10.3,27.2,25.6s-10.8,25.7-25.3,25.7-23.5-9.3-24.6-23.8h0Z"
        />
      </g>
    </svg>
  </SvgIcon>
);
