import React, { Component } from 'react';
import { Colours } from '../../resources';

const TICK_ID = 'tick';
const DROPSHADOW_ID = 'dropshadow';

export default class Gauge extends Component {
  renderDial = opts => {
    const style = {
      filter: `url(#${DROPSHADOW_ID})`,
    };
    return (<circle
      cx={opts.cX} cy={opts.cY} r={opts.radius} fill="none" stroke={'#cb00694d'}
      strokeWidth={opts.dialWidth} style={style} />);
  };

  defineShadow = () => (
    <filter
      id={DROPSHADOW_ID} x="-2%" y="-2%" width="140%" height="140%"
      filterUnits="userSpaceOnUse">
      {/* <feGaussianBlur in="SourceAlpha" stdDeviation="10" />
      <feOffset dx="-4" dy="4" result="offsetblur" /> */}
      <feDropShadow
        stdDeviation="5 5"
        in="SourceGraphic"
        dx={-2}
        dy={2}
        // floodColor="#000"
        floodOpacity="0.1"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        result="dropShadow"
      />
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  );

  defineTick = opts => {
    let tX1 = opts.cX + opts.radius - Math.max(opts.dialWidth, opts.progressWidth) / 2;
    let tX2 = tX1 - opts.tickLength;

    return (<line
      id={TICK_ID} x1={tX1} y1={opts.cY} x2={tX2} y2={opts.cY}
      stroke={opts.tickColor} strokeWidth={opts.tickWidth} />);
  };

  renderTickText = opts => {
    let tickAngles = [];
    for (let i = -90; i < 270; i += opts.tickInterval) {
      tickAngles.push(i);
    }
    const radius = opts.cX + 15;
    return (
      <g className="ticks">
        {tickAngles.map((tickAngle, idx) => {
          const deltaX = radius * Math.cos((tickAngle / 180.0) * 3.1415);
          const deltaY = radius * Math.sin((tickAngle / 180.0) * 3.1415);
          return (
            <text
              style={{ backgroundColor: 'red' }}
              key={`tick-text-${idx}`}
              x={opts.cX + deltaX}
              y={opts.cY + deltaY + 15}
              fontSize={opts.progressFontSize}
              transform={`rotate(90 ${opts.cX} ${opts.cY})`}
              textAnchor="middle"
              fill={opts.tickColor}>
              {Math.floor((idx / tickAngles.length) * opts.maximumTextValue)}
            </text>
          );
        })}
      </g>
    );
  };

  renderProgress = (opts, index) => {
    let offset = opts.circumference * (1 - opts.values.slice(0, index).reduce((a, b) => a + b, 0) / opts.maximumValue);
    const segmentLength = (opts.circumference * opts.values[index]) / opts.maximumValue;
    return (
      <circle
        cx={opts.cX}
        cy={opts.cY}
        r={opts.radius}
        fill="none"
        stroke={opts.colors[index]}
        strokeWidth={opts.progressWidth}
        strokeDasharray={`${segmentLength} ${opts.circumference - segmentLength}`}
        strokeDashoffset={offset}
        strokeLinecap={opts.progressRoundedEdge ? 'round' : 'butt'}
      />
    );
  };

  render() {
    let opts = Object.assign({}, this.props);

    let { size, dialWidth } = opts;

    let cX = size / 2;
    let cY = size / 2;
    let radius = (size - 2 * dialWidth) / 2;
    let diameter = 2 * radius;
    let circumference = 2 * Math.PI * radius;
    opts = Object.assign(opts, {
      cX,
      cY,
      radius,
      diameter,
      circumference,
    });

    return (
      <svg style={opts.style} viewBox={`-75 -75 ${size + 150} ${size + 150}`}>
        <defs>
          {this.defineTick(opts)}
          {this.defineShadow()}
        </defs>
        <g transform={`rotate(-90 ${cX} ${cY})`}>
          {this.renderDial(opts)}
          {this.renderTickText(opts)}
          {this.renderProgress(opts, 0)}
          {this.renderProgress(opts, 1)}
        </g>
      </svg>
    );
  }
}

Gauge.defaultProps = {
  size: 500,

  dialWidth: 30,
  dialColor: Colours.darkPrimary,

  tickLength: 3,
  tickWidth: 1,
  tickColor: Colours.white,
  tickInterval: 45,

  maximumValue: 100,
  maximumTextValue: 400,
  values: [25, 50],
  progressWidth: 30,
  colors: [Colours.primary, Colours.white],
  progressRoundedEdge: false,
  progressFont: 'Serif',
  progressFontSize: '40',
};
